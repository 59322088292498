<template>
  <!-- Create new Workflow dialog -->
  <v-dialog v-model="value" height="200" width="400">
    <v-card :loading="loading">
      <v-card-title>
        {{ isEdited ? 'Edit workflow' : 'Create new workflow' }}
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="editedItem.displayName" label="Workflow name" />
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn @click="close">Close</v-btn>
        <v-btn @click="save" color="primary" :disabled="!completed || loading">{{
          isEdited ? 'Save changes' : 'Create workflow'
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
const defaultItem = () => {
  return {
    displayName: null,
  };
};
const defaultState = () => {
  return {
    editedItem: defaultItem(),
    loading: false,
  };
};
export default {
  name: 'WorkflowEditor',
  data() {
    return {
      ...defaultState(),
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return defaultItem();
      },
    },
    value: Boolean,
  },
  computed: {
    completed() {
      return !!this.editedItem.displayName;
    },
    isEdited() {
      return !!this.$store.state.dialogs.workflowEditor?.item?.id;
    },
  },
  methods: {
    close() {
      this.$store.commit('toggleWorkflowEditor', { open: false });
    },
    async createWorkflow() {
      const { providerId } = this.$store.state.user;
      const { displayName: name } = this.editedItem;

      try {
        const result = await this.$apollo.mutate({
          mutation: await import('../graphql/Mutation/CreateWorkflow.gql'),
          variables: {
            providerId,
            workflow: {
              displayName: name,
            },
          },
        });

        this.$store.commit('setActiveWorkflowVersion', {});
        const newWorkflow = result.data.createWorkflow;
        this.$store.commit('setActiveWorkflow', newWorkflow);
        this.$emit('refresh');
      } catch (e) {
        this.$store.dispatch('setNotification', {
          color: 'error',
          timeout: 15000,
          text: `Error creating workflow: ${e}`,
        });
      }
    },
    init() {
      Object.assign(this, defaultState());
      if (this.$store.state.dialogs.workflowEditor.item) {
        const editedItem = Object.assign(
          defaultItem(),
          this.$store.state.dialogs.workflowEditor.item
        );
        this.editedItem = editedItem;
      }
    },
    async updateWorkflow() {
      const { providerId } = this.$store.state.user;
      const { displayName, id: workflowId, activeVersion } = this.editedItem;

      try {
        await this.$apollo.mutate({
          mutation: await import('../graphql/Mutation/UpdateWorkflow.gql'),
          variables: {
            providerId,
            activeVersion,
            workflow: {
              displayName,
            },
            workflowId,
          },
        });
        this.$emit('refresh');
      } catch (e) {
        this.$store.dispatch('setNotification', {
          color: 'error',
          timeout: 15000,
          text: `Error updating workflow: ${e}`,
        });
      }
    },
    async save() {
      this.loading = true;
      if (this.isEdited) {
        await this.updateWorkflow();
      } else {
        await this.createWorkflow();
      }
      this.loading = false;
      this.close();
    },
  },
  watch: {
    value(val) {
      if (!val) return;
      this.init();
    },
  },
};
</script>
